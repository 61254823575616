<template>
  <div>

    <div v-if="show === 'yes'">
      <div class="mode-center-big">
        <div class="mode-center">
          <div class="lh1 maxw2 mx-auto">
            <!--  
            <div class="text-hdr-3 pt-16 pb-8">{{textpage_title}}</div>
            -->
            <div class="text-subhdr-3 wysiwyg_text py-16" v-html="textpage_data"></div>
          </div>
        </div>

        <div class="pb-4"></div>

      </div>    
    </div>

    <page_404 v-if="show === '404'" />

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import page_404 from '@/components/page_404.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'TextPage',
  components: {
    page_404
  },
  data() {
    return {
      textpage_data: null,
      textpage_title: null,
      show: null
    }
  },
  mounted() {

    lv.tp = globals.api_data['textpages_fetch'].items
    if(lv.tp[this.$route.params.textpage]){
      this.textpage_title = lv.tp[this.$route.params.textpage].title
      this.textpage_data = lv.tp[this.$route.params.textpage].text
      this.show = 'yes'
    }else{
      this.show = '404'
    }

  },
  methods: {
  }
}
</script>

<style scoped>


</style>
<template>
  <div class="about">


    <div class="vspace_top_page"></div>
    <div class="mode-center">

      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1">
        <div class="">
          <div class="text-hdr-1">
            {{aboutData.title}}
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3">
            {{aboutData.subtitle}}
          </div>
        </div>
        <div class="">
          <div class="text-center">
            <img :src="aboutData.image" class="inline-block" />
          </div>

        </div>
      </div>

      <div class="vspace_middle_page_4"></div>



      <div v-for="(item, index) in items_about_grid_2" :key="index" 
        class="grid grid-cols-2 gap-5 gap-y-9 mxmd:grid-cols-1 pb-12" 
        :class="{ 'mb-20' : index % 2 === 1}"
      >
        <div :class="{ 'text-right' : index % 2 === 1}" v-if="item.img">
          <img :src="item.img" class="inline-block" />
        </div>
        <div :class="{ 'mnmd:order-first' : index % 2 === 1, 'col-span-2' : !item.img}">
          <div class="text-hdr-3 pad-col-page-1">
            {{item.title}}
          </div>
          <div class="text-subhdr-3 text-col-page-1" v-html="item.text">
          </div>
        </div>
      </div>


      <div class="vspace_middle_page_5"></div>

      <div class="text-center" v-if="show_button_1">
        <btn_page :to="data_button_1.link" :text="data_button_1.text" />
      </div>

    </div>


    <div class="vspace_middle_page_6"></div>
    <div class="bg-white">
      <div class="mode-center-big">
        <div class="mode-center">

          <div class="grid grid-cols-3 gap-4 mxmd:grid-cols-1 mxxl:grid-cols-2 pt-16 items-start">
            <div v-for="(item, index) in items_about_grid_3" :key="index"
            class="pt-8 flex content-end flex-wrap" >
              <div v-if="item.img">
                <img :src="item.img" class="mx-auto" />
              </div>
              <div>
                <div class="text-hdr-5 pt-2">
                  {{item.title}}
                </div>
                <div class="text-subhdr-3 pt-4 maxh1" v-html="item.text">
                </div>
              </div>
            </div>
          </div>

          <div class="vspace_middle_page_7"></div>

          <div class="text-center pt-3">
            <btn_page :to="data_button_2.link" :text="data_button_2.text" />
          </div>

          <div class="vspace_middle_page_8"></div>

          <div class="text-subhdr-3 wysiwyg_text lh1 maxw1 mx-auto" v-html="aboutData.text_section_4"></div>

          <div class="vspace_middle_page_7"></div>

        </div>
      </div>
    </div>

    <hero_cta :cta_data="cta_data" v-if="show_cta" />

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import btn_page from '@/components/ui/btn_page.vue'
import hero_cta from '@/components/ui/hero_cta.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'Home',
  components: {
    btn_page,
    hero_cta
  },
  data() {
    return {
      aboutData:{},
      show_cta: false,
      cta_data:{
        text: 'Hier een call-to-action',
        button: {
          link: '/registreren',
          text: 'Registreren'
        }
      },
      show_button_1: true,
      data_button_1:{
          link: '/registreren',
          text: 'Registreren'
      },
      show_button_2: true,
      data_button_2:{
          link: '/registreren',
          text: 'Registreren'
      },
      items_about_grid_2:[],
      items_about_grid_3:[]
    }
  },
  mounted() {

    lv.thisVue = this

    lv.dataTask = "about_fetch"
    lv.dataEvent = "data_about_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e)
    })

    function dataLoaded(d) {

      lv.about_data = globals.api_data[lv.dataTask].item

      lv.about_data_prep = {}
      lv.about_data_prep.title = lv.about_data.title
      lv.about_data_prep.subtitle = lv.about_data.subtitle
      lv.about_data_prep.image = globals.api_data[lv.dataTask].resources[globals.api_data[lv.dataTask].item.image].file
      lv.about_data_prep.title_section_2 = lv.about_data.title_section_2
      lv.about_data_prep.text_section_4 = lv.about_data.text_section_4

      lv.items_about_section_2 = []
      lv.s2_total = Object.keys(lv.about_data.page_parts_section_2).length
      lv.nr = 0
      while(lv.nr < lv.s2_total){
        lv.tmp = lv.about_data.page_parts_section_2[lv.nr]
        lv.img = null
        if(globals.api_data[lv.dataTask].resources[lv.tmp.image]){
          lv.img = globals.api_data[lv.dataTask].resources[lv.tmp.image].file
        }
        lv.items_about_section_2.push({
          title: lv.tmp.title,
          text: lv.tmp.text,
          img: lv.img,
        })
        lv.nr++
      }
      lv.thisVue.items_about_grid_2 = lv.items_about_section_2

      lv.data_button_1 = JSON.parse(lv.about_data.button)
      if(lv.data_button_1 && lv.data_button_1 != '' && lv.data_button_1 != '{}'){
        lv.thisVue.show_button_1 = true
        lv.thisVue.data_button_1 = lv.data_button_1
      }

      lv.items_section_3 = []
      lv.s3_total = Object.keys(lv.about_data.page_parts_section_3).length
      lv.nr = 0
      while(lv.nr < lv.s3_total){
        lv.tmp = lv.about_data.page_parts_section_3[lv.nr]
        lv.img = null
        if(globals.api_data[lv.dataTask].resources[lv.tmp.image]){
          lv.img = globals.api_data[lv.dataTask].resources[lv.tmp.image].file
        }
        lv.items_section_3.push({
          title: lv.tmp.title,
          text: lv.tmp.text,
          img: lv.img,
        })
        lv.nr++
      }
      lv.thisVue.items_about_grid_3 = lv.items_section_3

      lv.data_button_2 = JSON.parse(lv.about_data.button_2)
      if(lv.data_button_2 && lv.data_button_2 != '' && lv.data_button_2 != '{}'){
        lv.thisVue.show_button_2 = true
        lv.thisVue.data_button_2 = lv.data_button_2
      }

      lv.cta_data = JSON.parse(lv.about_data.call_to_action)
      if(lv.cta_data && lv.cta_data != '' && lv.cta_data != '{}'){
        lv.thisVue.show_cta = true
        lv.thisVue.cta_data = lv.cta_data
      }

      lv.thisVue.aboutData = lv.about_data_prep
    }

    
  }
}
</script>

<style scoped>


</style>
<template>
  <div class="contact">

    <div class="vspace_top_page"></div>
    <div class="mode-center">

      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1">
        <div class="">
          <div class="text-hdr-1">
            {{contactData.title}}
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3" v-html="contactData.subtitle">
          </div>
        </div>
        <div class="">
          <div class="text-right">
            <img :src="contactData.image" class="inline-block" />
          </div>
        </div>
      </div>

      <div class="pt-20 pl-7">
        <img :src="contactData.image_2" />
      </div>

    </div>

    <div class="vspace_middle_page"></div>

    <hero_cta :cta_data="cta_data" v-if="show_cta" />

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import hero_cta from '@/components/ui/hero_cta.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'Contact',
  components: {
    hero_cta
  },
  data() {
    return {
      contactData: {},
      show_cta: false,
      cta_data:{
        text: 'Hier een call-to-action',
        button: {
          link: '/registreren',
          text: 'Registreren'
        }
      },
      
    }
  },
  mounted() {

    lv.thisVue = this

    lv.dataTask = "contact_fetch"
    lv.dataEvent = "data_contact_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e)
    })

    function dataLoaded(d) {

      lv.contact_data = globals.api_data[lv.dataTask].item

      lv.thisVue.contactData = {}
      lv.thisVue.contactData.title = lv.contact_data.title
      lv.thisVue.contactData.subtitle = lv.contact_data.subtitle
      lv.thisVue.contactData.image = globals.api_data[lv.dataTask].resources[globals.api_data[lv.dataTask].item.image].file
      lv.thisVue.contactData.image_2 = globals.api_data[lv.dataTask].resources[globals.api_data[lv.dataTask].item.image_2].file

      lv.cta_data = JSON.parse(lv.contact_data.call_to_action)
      if(lv.cta_data && lv.cta_data != '' && lv.cta_data != '{}'){
        lv.thisVue.show_cta = true
        lv.thisVue.cta_data = lv.cta_data
      }

    }

  }
}
</script>

<style scoped>


</style>
<template>
  <div class="faq">


    <div class="vspace_top_page"></div>
    <div class="mode-center">
      <div class="grid grid-cols-2 gap-4 mxmd:grid-cols-1">
        <div class="">
          <div class="text-hdr-1">
            {{faqData.title}}
          </div>
          <div class="text-subhdr-1 font-mono mt-3 pr-3">
            {{faqData.subtitle}}
          </div>
        </div>
        <div class="">
          <div class="text-center">
            <img :src="faqData.image" class="inline-block hero-img" />
          </div>

        </div>
      </div>
    </div>

    <div class="pt-5"></div>

    <div class="bg-white">
      <div class="mode-center-big">
        <div class="mode-center">
          <div class="faq-wrap mx-auto pt-14 pb-8">

            <faq_node 
              v-for="(item, index) in items_faq"
              :key="index" 
              :id="'faq_' + index" 
              :question="item.question" 
              :answer="item.answer" 
            />

          </div>
        </div>
      </div>
    </div>
    
    <hero_cta :cta_data="cta_data" v-if="show_cta" />



  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import faq_node from '@/components/ui/faq_node.vue'
import hero_cta from '../components/ui/hero_cta.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'Faq',
  components: {
    faq_node,
    hero_cta
  },
  data() {
    return {
      faqData:{},
      show_cta: false,
      cta_data:{
        text: 'Hier een call-to-action',
        button: {
          link: '/registreren',
          text: 'Registreren'
        }
      },
      items_faq:[]
    }
  },
  mounted() {

    lv.thisVue = this

    lv.dataTask = "faq_fetch"
    lv.dataEvent = "data_faq_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e)
    })

    function dataLoaded(d) {

      lv.thisVue.items_faq = []
      lv.faq_data = globals.api_data[lv.dataTask].item

      lv.thisVue.faqData = {}
      lv.thisVue.faqData.title = lv.faq_data.title
      lv.thisVue.faqData.subtitle = lv.faq_data.subtitle
      lv.thisVue.faqData.image = globals.api_data[lv.dataTask].resources[globals.api_data[lv.dataTask].item.image].file

      lv.items_faq = JSON.parse(lv.faq_data.q_and_a)
      lv.nr = 0
      while(lv.nr < lv.items_faq.length){
        lv.node = {
          question: lv.items_faq[lv.nr]['Question'],
          answer: lv.items_faq[lv.nr]['Answer'],
        }
        lv.thisVue.items_faq.push(lv.node)
        lv.nr++
      }

      lv.cta_data = JSON.parse(lv.faq_data.call_to_action)
      if(lv.cta_data && lv.cta_data != '' && lv.cta_data != '{}'){
        lv.thisVue.show_cta = true
        lv.thisVue.cta_data = lv.cta_data
      }

    }

  }
}
</script>

<style scoped>

.hero-img{
  position: relative;
  top: -41px;
}
.faq-wrap{
  max-width: 860px;
}

@media (max-width: 720px){
  .hero-img{
    position: relative;
    top: 0px;
  }
}


</style>